
//获取当前时间
function getCurrentTime() {
	function getNow(s) {
		return s < 10 ? '0' + s : s;
	}
	var myDate = new Date();
	//获取当前年
	var year = myDate.getFullYear();
	//获取当前月
	var month = myDate.getMonth() + 1;
	//获取当前日
	var date = myDate.getDate();
	var h = myDate.getHours();       //获取当前小时数(0-23)
	var m = myDate.getMinutes();     //获取当前分钟数(0-59)
	var s = myDate.getSeconds();
	var now = year + '-' + getNow(month) + "-" + getNow(date) + " " + getNow(h) + ':' + getNow(m) + ":" + getNow(s);
	// var currentTime = year + '/' + getNow(month) + "/" + getNow(date);
	var getday = "星期" + "日一二三四五六".charAt(new Date().getDay());
	var currentTimeObj = {
		"date": year + '/' + getNow(month) + "/" + getNow(date),
		"time": getNow(h) + ':' + getNow(m),
		"day": getday,
		"formatOne": now
	}
	return currentTimeObj;
}


//将时间戳转换为时间
function timestampToTime(timestamp) {
	var date = new Date(timestamp); //时间戳为10位需*1000，时间戳为13位的话不需乘1000
	var Y = date.getFullYear() + '-';
	var M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
	var D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
	var h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
	var m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
	var s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return Y + M + D + h + m + s;
}

//格式时间
function formatCtime(dateStr) {
	// 格式化dateStr时间
	var dt = new Date(dateStr);

	// 获取年
	var year = dt.getFullYear();

	// 获取月
	var month = dt.getMonth() + 1; // 月份 0 - 11，需要 + 1

	// 获取日
	// var day = dt.getDate();

	// 拼接 yyyy-mm-dd
	return year + '/' + month
}

/**
 * 对日期进行格式化:
 * 示例:
 * yyyy-MM-dd (年-月-日), 或 yy-M-d (年-月-日).
 * @param date 要格式化的日期
 * @param {string} format 进行格式化的模式字符串
 * @return {string}
 * @author yanis.wang
 * @see http://yaniswang.com/frontend/2013/02/16/dateformat-performance/
 * 支持的模式字母有:
 * y: 年
 * M: 月
 * d: 日
 * h: 时
 * m: 分
 * s: 秒
 * q: 季度
 * S: 毫秒
 */
function dateFormat(date, format) {
	if (typeof date === 'string' && /^\d+$/.test(date)) {
		date = parseInt(date);
	}

	date = new Date(date);
	if (date.toString() === 'Invalid Date') {
		throw 'Error, parameter date is not valid: Invalid Date.';
	}

	var map = {
		"M": date.getMonth() + 1, // 月
		"d": date.getDate(), // 日
		"h": date.getHours(), // 时
		"m": date.getMinutes(), // 分
		"s": date.getSeconds(), // 秒
		"q": Math.floor((date.getMonth() + 3) / 3), // 季度
		"S": date.getMilliseconds(), // 毫秒
	};

	format = format.replace(
		/([yMdhmsqS])+/g,
		function (match, p) {
			var v = map[p];
			if (v !== undefined) {
				// 月/日/时/分/秒 的格式为 xx 时始终返回两位数，若只有一位，用 0 在前面补位
				if (match.length > 1 && p !== 'S') {
					v = '0' + v;
					v = v.substring(v.length - 2);
				}
				return v;
			} else if (p === 'y') {
				// 处理 年
				// 传入年的格式为 yy 返回年份末尾两位
				if (match.length === 2) {
					return (date.getFullYear() + '').substring(2);
				} else {
					// yyyy/yyy/y 返回完整的年
					return (date.getFullYear() + '');
				}
			}
		});
	return format;
}

// js描述转化为时分秒的方法：
function formatSeconds(value) {
	var secondTime = parseInt(value);// 秒
	var minuteTime = 0;// 分
	var hourTime = 0;// 小时
	if(secondTime > 60) {//如果秒数大于60，将秒数转换成整数
		//获取分钟，除以60取整数，得到整数分钟
		minuteTime = parseInt(secondTime / 60);
		//获取秒数，秒数取佘，得到整数秒数
		secondTime = parseInt(secondTime % 60);
		//如果分钟大于60，将分钟转换成小时
		if(minuteTime > 60) {
			//获取小时，获取分钟除以60，得到整数小时
			hourTime = parseInt(minuteTime / 60);
			//获取小时后取佘的分，获取分钟除以60取佘的分
			minuteTime = parseInt(minuteTime % 60);
		}
	}
	var result = "" + parseInt(secondTime) + "秒";

	if(minuteTime > 0) {
		result = "" + parseInt(minuteTime) + "分" + result;
	}
	if(hourTime > 0) {
		result = "" + parseInt(hourTime) + "小时" + result;
	}
	return result;
}
// function userMedia(constraints, success, error) {
// 	if (navigator.mediaDevices.getUserMedia) {
// 		userMedia = function (constraints, success, error) {
// 			navigator.mediaDevices.getUserMedia(constraints).then(success).catch(error)
// 		}
// 	} else if (navigator.webkitGetUserMedia) {
// 		userMedia = function (constraints, success, error) {
// 			navigator.webkitGetUserMedia(constraints, success, error)
// 		}
// 	} else if (navigator.mozGetUserMedia) {
// 		userMedia = function (constraints, success, error) {
// 			navigator.mozGetUserMedia(constraints, success, error)
// 		}
// 	} else if (navigator.getUserMedia) {
// 		userMedia = function (constraints, success, error) {
// 			navigator.getUserMedia(constraints, success, error)
// 		}
// 	}

// 	userMedia(constraints, success, error)
// }


export default {
	getCurrentTime,
	timestampToTime,
	formatCtime,
	dateFormat,
	formatSeconds
	// userMedia
}