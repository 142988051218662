var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"headInfoPage"},[_vm._m(0),_c('div',{staticClass:"leftCon"},[_c('div',{staticClass:"item"},[_vm._v("值班人员："),_c('span',[_vm._v(_vm._s(_vm.userInfo.account))])]),_vm._m(1),_c('div',{staticClass:"item"},[_vm._v("工号："),_c('span',[_vm._v(_vm._s(_vm.userInfo.user_no))])]),_vm._m(2),_c('div',{staticClass:"item"},[_vm._v(" 登录时长："),_c('span',[_vm._v(_vm._s(_vm.formatSeconds(Number(_vm.startTime))))])])]),_vm._m(3),_c('div',{staticClass:"rightCon"},[_c('div',{staticClass:"item"},[_vm._v(" 登录时间："),_c('span',[_vm._v(_vm._s(_vm.loginTime))])]),_vm._m(4),_vm._m(5)]),_c('div',{staticClass:"userInfo"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":_vm.userInfo.avatar,"alt":""}})])]),_c('div',{staticClass:"layout",on:{"click":_vm.layout}},[_c('img',{attrs:{"src":require("@/assets/images/index/exit_button.png"),"alt":""}})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"leftTime"},[_c('div',{staticClass:"year"},[_vm._v("2020.01.09")]),_c('div',{staticClass:"hour"},[_vm._v("14:14:36")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/index/ding_list.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/index/ding_list.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bodyCon"},[_c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/index/top_logo.png"),"alt":""}})]),_c('div',{staticClass:"txt"},[_vm._v("远程守护值勤平台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"img"},[_c('img',{attrs:{"src":require("@/assets/images/index/ding_list.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_vm._v("当月累计时长："),_c('span',[_vm._v("105小时43分")])])
}]

export { render, staticRenderFns }