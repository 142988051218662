<template>
  <div class="headInfoPage">
    <div class="leftTime">
      <div class="year">2020.01.09</div>
      <div class="hour">14:14:36</div>
    </div>
    <div class="leftCon">
      <div class="item">值班人员：<span>{{userInfo.account}}</span></div>
      <div class="img">
        <img src="@/assets/images/index/ding_list.png" alt="" />
      </div>
      <div class="item">工号：<span>{{userInfo.user_no}}</span></div>
      <div class="img">
        <img src="@/assets/images/index/ding_list.png" alt="" />
      </div>
      <div class="item">
        登录时长：<span>{{ formatSeconds(Number(startTime)) }}</span>
      </div>
    </div>
    <div class="bodyCon">
      <div class="img">
        <img src="@/assets/images/index/top_logo.png" alt="" />
      </div>
      <div class="txt">远程守护值勤平台</div>
    </div>
    <div class="rightCon">
      <div class="item">
        登录时间：<span>{{ loginTime }}</span>
      </div>
      <div class="img">
        <img src="@/assets/images/index/ding_list.png" alt="" />
      </div>
      <div class="item">当月累计时长：<span>105小时43分</span></div>
    </div>
    <div class="userInfo">
      <div class="img">
        <img :src="userInfo.avatar" alt="" />
      </div>
    </div>
    <div class="layout" @click="layout">
      <img src="@/assets/images/index/exit_button.png" alt="" />
    </div>
  </div>
</template>
<script>
import format from "@/utils/utils";
export default {
  name: "",
  components: {},
  data() {
    return {
      userInfo: JSON.parse(localStorage.getItem("userInfo"))
        ? JSON.parse(localStorage.getItem("userInfo"))
        : {},
      loginTime: "",
      startTime: 0,
      timer: null,
    };
  },
  created() {
    let loginTime = format.dateFormat(new Date(), "yyyy-M-d hh:mm:ss");
    this.loginTime = loginTime;
    this.initTime();
  },
  computed: {},
  beforeDestroy() {
    clearInterval(this.timer); //关闭
  },
  methods: {
    formatSeconds(value) {
      var secondTime = parseInt(value); // 秒
      var minuteTime = 0; // 分
      var hourTime = 0; // 小时
      if (secondTime > 60) {
        //如果秒数大于60，将秒数转换成整数
        //获取分钟，除以60取整数，得到整数分钟
        minuteTime = parseInt(secondTime / 60);
        //获取秒数，秒数取佘，得到整数秒数
        secondTime = parseInt(secondTime % 60);
        //如果分钟大于60，将分钟转换成小时
        if (minuteTime > 60) {
          //获取小时，获取分钟除以60，得到整数小时
          hourTime = parseInt(minuteTime / 60);
          //获取小时后取佘的分，获取分钟除以60取佘的分
          minuteTime = parseInt(minuteTime % 60);
        }
      }
      var result = "" + parseInt(secondTime) + "秒";

      if (minuteTime > 0) {
        result = "" + parseInt(minuteTime) + "分" + result;
      }
      if (hourTime > 0) {
        result = "" + parseInt(hourTime) + "小时" + result;
      }
      return result;
    },
    initTime() {
      this.timer = setInterval(() => {
        this.startTime++;
      }, 1000);
    },
    //退出账号
    layout() {
      this.$confirm("确认退出吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
        confirmButtonClass: "confirm",
      })
        .then(() => {
          this.$message({
            type: "success",
            message: "退出成功!",
          });
          localStorage.removeItem("token");
          this.$router.push("/login");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消退出",
          });
        });
    },
  },
};
</script>
<style lang='scss'  scoped>
.headInfoPage {
  position: relative;
  height: 100%;
  background: url(../../assets/images/index/title_di.png) no-repeat;
  .leftTime {
    position: absolute;
    left: 80px;
    top: 16px;
    width: 70px;
    height: 100%;
    .year {
      font-size: 14px;
      color: #00ffff;
      line-height: 9px;
    }
    .hour {
      font-size: 18px;
      color: #00ffff;
      line-height: 28px;
    }
  }
  .leftCon {
    position: absolute;
    left: 10%;
    top: 16px;
    display: flex;
    justify-content: space-between;
    width: 500px;
    height: 100%;
    padding-top: 5px;

    .item {
      color: #00ffff;
      span {
        color: #fff;
      }
    }
  }
  .bodyCon {
    position: absolute;
    left: 50%;
    top: 10px;
    transform: translateX(-50%);
    width: 290px;
    display: flex;
    justify-content: space-between;
    .img {
      width: 86px;
      height: 40px;
      img {
        width: 100%;
      }
    }
    .txt {
      width: 190px;
      height: 20px;
      line-height: 35px;
      font-size: 22px;
      font-family: YouSheBiaoTiHei;
      color: #feeb1a;
    }
  }
  .rightCon {
    position: absolute;
    left: 65%;
    top: 16px;
    display: flex;
    justify-content: space-between;
    width: 450px;
    height: 100%;
    padding-top: 5px;
    .item {
      color: #00ffff;
      span {
        color: #fff;
      }
    }
  }
  .userInfo {
    position: absolute;
    right: 59px;
    top: 11px;
    .img {
      width: 40px;
      height: 40px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }
    }
  }
  .layout {
    position: absolute;
    right: 0;
    top: 0;
    width: 40px;
    height: 100%;
    img {
      height: 100%;
    }
  }
}
</style>
